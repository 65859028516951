import Box from '@spraoi/base/Box';
import React from 'react';
import Section from '../../components/Section';
import Icon from '../../components/Icon';

const FourOhFour = () => (
  <Section
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: ['column', null, 'row'],
      justifyContent: 'center',
      textAlign: ['center', null, 'left'],
    }}
  >
    <Icon
      svg="error-bot"
      sx={{ ml: [null, null, 7], mr: [null, null, 5], py: [7, null, 0] }}
      maxWidth="20rem"
      minWidth="20rem"
    />
  </Section>
);

export default FourOhFour;
